@media only screen and (max-width:500px){
  .Contacts{
    position: relative;
      display: grid;
      text-align: left;
      padding:50px 30px 20px 30px;
      margin-top: 3%;
      margin-left: 3%;
      margin-right: 3%;
      background-repeat: no-repeat;
      background-size: 22%;
      background-attachment: fixed;
      background-position: 89% 55%;
      border-radius: 20px;
      background-color:rgb(245, 245, 245);
      overflow:hidden;
  }
  .Tessera{
    position: relative;
    display: grid;

    text-align: left;
    padding:50px 30px 20px 30px;
    margin-top: 3%;
    margin-left: 3%;
    margin-right: 3%;
    background-repeat: no-repeat;
    background-size: 22%;
    background-attachment: fixed;
    background-position: 89% 55%;
    border-radius: 20px;
    background-color:rgb(245, 245, 245);
    height: 600px;
    overflow:hidden;
  }
  .aicsLogoSpanTessera{
    position: relative;
    width:20%;
    top:4px;
}
.mapouter{
  position: relative;
  margin-top: 30px;
  display:none;
}
.mapouterMobile{
  position: relative;
  display:block;
  left:-50px;
}
    .serviceImg{
        position: relative;
        margin-top: 50px;
        width: 100%;
        left: -50px;
        display: none;
      }
      .recImg{
        position: relative;
        margin-top: 40px;
        width: 80%;
        left: -40px;
        display: none;
      }
      .tesseraImg{
        position: relative;
        margin-top: 100px;
        width: 80%;
        left:-10%;
      }
      input[type=text], input[type=email],select {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
    textarea {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      input[type=submit] {
        width: 100%;
        background-color: #4CAF50;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
      
      input[type=submit]:hover {
        background-color: #45a049;
      }
      .buttonStatuto{
        position: relative;
        left: 16%;
      }
      .formContainer {
        border-radius: 5px;
        padding: 20px;
      }
}