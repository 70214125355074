
/* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*//* PC PC PC PC*/

@media only screen and (min-width:1000px){
    .pStyle{
        font-size: 16px;
    }
    .HomeContainer{
        position: relative;
        font-family: Arial, Helvetica, sans-serif;
    }
    .salaProveImage{
        width:100%;
        height: 95vh;
        object-fit: cover;
        opacity: 0.7;
    }
    .Header{
        position: relative;
        width: 100%;
        height: 69vh;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: right 0 bottom 10px;
        margin-top:0px;

    }
    .logoContainer{
        position:absolute;
        border-radius:100%;
        width:230px;
        height: 230px;
        margin:10% 0 0 42.8%;

    }
    .logo{
        width:100%;
        height: 100%;
    }
    .aicsLogoSpan{
        position: relative;
        width:3%;
        top:4px;
    }
    .salaMapRow{
        position:relative;
        width:85%;
        margin-left:7.5%;
        padding:10px;
        /*border: 1px solid red;*/
        display:flex;
        justify-content:space-evenly;
    }

    .salaMapColumn {
        position: relative;
        top:20px;
        float: left;
        width:25%;
        height: 370px;
        text-align: left;
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
    }
    .salaMapColumn:hover{
        box-shadow: 1px 1px 15px 5px rgba(0,0,0,0.28);
    }
    .salaMapImage{
        width:100%;
        height: 100%;
        object-fit: cover;
        filter:brightness(70%);
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
    }
    .salaMapImage:hover{
        filter:brightness(85%);
    }
    .salaName{
        position: absolute;
        top: 0%;
        left:-1px;
        color:black;
        font-size: 20px;
        font-weight: bold;
        background-color: rgb(255, 255, 255);
        padding:10px;
        border-radius: 10px 0 10px 0;
    }
    .salaButtonContainer{
        position: relative;
        bottom:25%;
        display: flex;
    }
    .salaButton{
        position: relative;
        width: 150px;
        background-color: rgb(214, 214, 214);
        color:rgb(63, 63, 63);
        padding:15px;
        margin: auto;
        font-family: inherit;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
    .salaButton:hover{
        background-color: rgb(255, 255, 255);
    }
}

