@media only screen and (min-width:1000px){
    .Service{
        position: relative;
        display: grid;
        grid-template-columns: 35% 35% 30%;
        text-align: left;
        padding:50px 30px 20px 30px;
        margin-top: 3%;
        margin-left: 3%;
        margin-right: 3%;
        background-repeat: no-repeat;
        background-size: 22%;
        background-attachment: fixed;
        background-position: 89% 55%;
        border-radius: 20px;
        background-color:rgb(245, 245, 245);
        height: 600px;
        overflow:hidden;
    }
    .Contacts{
      position: relative;
        display: grid;
        grid-template-columns: 35% 35% 30%;
        text-align: left;
        padding:50px 30px 20px 30px;
        margin-top: 3%;
        margin-left: 3%;
        margin-right: 3%;
        background-repeat: no-repeat;
        background-size: 22%;
        background-attachment: fixed;
        background-position: 89% 55%;
        border-radius: 20px;
        background-color:rgb(245, 245, 245);
        height: 600px;
        overflow:hidden;
    }
    .Tessera{
      position: relative;
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: left;
      padding:50px 30px 20px 30px;
      margin-top: 3%;
      margin-left: 3%;
      margin-right: 3%;
      background-repeat: no-repeat;
      background-size: 22%;
      background-attachment: fixed;
      background-position: 89% 55%;
      border-radius: 20px;
      background-color:rgb(245, 245, 245);
      height: 600px;
      overflow:hidden;
    }
    .aicsLogoSpanTessera{
      position: relative;
      width:10%;
      top:4px;
  }
    .mapouter{
      position: relative;
      margin-top: 30px;
      display:block;
    }
    .mapouterMobile{
      position: relative;
      display:none;
    }
    .serviceImg{
      position: relative;
      margin-top: 50px;
      width: 100%;
      left: -50px;
    }
    .recImg{
      position: relative;
      margin-top: 40px;
      width: 80%;
      left: -40px;
    }
    .tesseraImg{
      position: relative;
      margin-top: 130px;
      width: 100%;

    }
    input[type=text], input[type=email], select {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
    textarea {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      input[type=submit] {
        width: 100%;
        background-color: #4CAF50;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
      
      input[type=submit]:hover {
        background-color: #45a049;
      }
      
      .formContainer {
        border-radius: 5px;
        padding: 20px;
      }
}