@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

/* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/
@media only screen and (max-width:500px){
    .Sala{
        position: relative;
        text-align: left;
        padding:25px 25px 25px 25px;
        margin-top: 10%;
        margin-left: 1%;
        margin-right: 1%;
        background: none;
        background-repeat: no-repeat;
        background-size: 0%;
        background-attachment: fixed;
        background-position: 89% 70%;
        border-radius: 20px;
        background-color:rgb(245, 245, 245);
        height: 720px;
    }
    .leftImage{
        width: 100%; 
        margin: -5% 0 5% 15%;  
        animation: fadeInFromNone 0.5s ease-out; 
    }
        .salaNameCarousel{
            position: relative;
            top: 31px;
            left:-1%;
            color:black;
            font-size: 20px;
            font-weight: bold;
            background-color: rgb(245, 245, 245);
            padding:10px;
            border-radius: 0 0 10px 0;
            z-index: 2;
        }
        /* salaProveCarousel from carousel css */
        /* salaProveCarouselImage from carousel css */
    .rightContent {
        position: relative;
        right:0%;
        margin-right: 0%;
        text-align: justify;
        animation: fadeInFromNone 0.5s ease-out; 
    }
        .summaryBar{
            position: relative;
            top:10px;
            margin:auto;
            padding:1%;
            width: 80%;
            background-color: rgb(235, 235, 235);
            border-radius: 30px;
            display: flex;
            justify-content: space-evenly;
        }
        .dividerSummary{
            border-left: 1px solid rgb(58, 58, 58);
        }
        .iconSummary{
            position: relative;
            margin-top: 10px;
        }
        .textSummary1{
            position: relative;
            font-size: 10px;
            margin-top: 2px;
        }
        .textSummary2{
            position: relative;
            font-size: 10px;
            margin-top: 2px;
        }
        .rightContent h1{
            font-size: 20px;
            text-align: center;
        }
        .rightContent p{
            background-color: rgb(245, 245, 245);
            border-radius: 10px;
            padding:10px;
            font-size: 13px;
        }
        .calendarPanel{
            position: relative;
            display: none;
            animation: fadeInFromNone 0.5s ease-out;
        }
        .calendarPanel h1{
            font-size: 20px;
            text-align: center;
        }
        .calendarPanel p{
            background-color: rgb(245, 245, 245);
            border-radius: 10px;
            padding:5px 0 0 5px;
            font-size: 13px;
        }
        .buttonCalendarContainer{
            position: absolute;
            width:100%;
            display: flex;
            bottom:30px;
            margin-left:-5%;
        }
        .buttonCalendar{
            margin: auto;
            left: 100px;
        }
    .CalendarRigth{
        position: relative;
        margin-left: 5%;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
}

