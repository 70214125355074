

/* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/ /* MOBILE MOBILE MOBILE MOBILE*/
@media only screen and (max-width:500px){
    .HomeContainer{
        position: relative;
        font-family: Arial, Helvetica, sans-serif;
        overflow-x: hidden;
    }
    .salaProveImage{
        width:100%;
        height: 95vh;
        object-fit: cover;
        opacity: 0.7;
    }
    .Header{
        position: relative;
        width: 100%;
        height: 40vh;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 644px 483px;
        background-position: right 0 top -120px;
        margin-top:0px;

    }
    .logoContainer{
        position:absolute;
        border-radius:100%;
        width:150px;
        height: 150px;
        margin:17% 33%;

    }
    .headerDescriptionContainer{
        padding:12px;
    }
    .pStyle{
        font-size: 12px;
    }
    .logo{
        width:100%;
        height: 100%;
    }
    .aicsLogoSpan{
        position: relative;
        width:9%;
        top:4px;
    }
    .salaMapRow{
        position:relative;
        width: 100%;
        display: flex;
        padding:5px;

        overflow-x:scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .salaMapRow::-webkit-scrollbar {
        display: none;
      }
    .salaMapColumn {
        position: relative;
        top:20px;
        float: left;
        width: 200px;
        height: 150px;
        margin-left:10px;
        margin-top: 5px;
        margin-bottom: 25px;
        text-align: left;
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
    }
    .salaMapColumn:hover{
        box-shadow: 1px 1px 15px 5px rgba(0,0,0,0.28);
    }
    .salaMapImage{
        width:100%;
        height: 100%;
        object-fit: cover;
        filter:brightness(70%);
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
    }
    .salaMapImage:hover{
        filter:brightness(85%);
    }
    .salaName{
        position: absolute;
        top: 0px;
        left:-1px;
        color:black;
        font-size: 8px;
        font-weight: bold;
        background-color: rgb(255, 255, 255);
        padding:5px;
        border-radius: 10px 0 10px 0;
    }
    .salaButton{
        display: none;
        position: relative;
        width: 90px;
        background-color: rgb(214, 214, 214);
        color:rgb(63, 63, 63);
        padding:5px;
        bottom:15px;
        left:-108px;
        font-family: inherit;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        font-size: 12px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        z-index: 10;
    }
    .salaButton:hover{
        background-color: rgb(255, 255, 255);
    }
}










