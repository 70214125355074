.App {
  position: relative;
  text-align: center;
  width: 100%;
}

.AppContainer{
  position: absolute;
  margin-top: 20px;
  width: 100%;
  /*background-image:linear-gradient(90deg,rgb(255, 255, 255),rgb(211, 211, 210));*/
  background-color: white;
  min-height: 100vh;
}
